var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.action === 'follow')?_c('v-btn',{staticClass:"text-none black--text font-weight-bold",class:{
      'px-0': _vm.fitWidth,
      'primary darken-1': _vm.hasBg,
    },attrs:{"color":"primary","loading":_vm.loading,"width":_vm.fitWidth ? 'fit-content' : '80',"min-width":_vm.fitWidth ? 'auto' : 'unset',"text":"","height":_vm.plain ? 'fit-content' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('follow')}}},[_vm._v(" Follow ")]):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-none black--text font-weight-bold",class:{
        'px-0': _vm.fitWidth,
        'primary darken-1': _vm.hasBg,
      },attrs:{"color":"white","text":"","height":_vm.plain ? 'fit-content' : '',"loading":_vm.loading,"min-width":_vm.fitWidth ? 'auto' : 'unset',"width":_vm.fitWidth ? 'fit-content' : '80'},on:{"click":function($event){$event.stopPropagation();return _vm.setOpenUnFollowDialog(true)}}},[_vm._v(" "+_vm._s(hover ? 'UnFollow' : 'Following')+" ")])]}}])}),_c('unfollow-dialog',{attrs:{"open":_vm.openUnFollowDialog,"profile":_vm.profile},on:{"closeDialog":function($event){return _vm.setOpenUnFollowDialog(false)},"unFollow":function($event){return _vm.unFollow()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }